@import "../../variables";

.list {
  max-width: 1440px;
  margin: auto;
  display: flex;
  align-items: center;

  &-item {
    padding: 32px 16px;
    flex: 1;
    cursor: pointer;
    position: relative;

    &:hover .img {
      box-shadow: 0px 2px 8px 0px rgba(162, 42, 71, 0.2);
    }
  }
}

.title {
  font-size: 18px;
  padding-bottom: 8px;
  color: #444;
}

.sub-title {
  color: #aaa;
  font-size: 14px;
  padding-bottom: 16px;
}

.img {
  width: 256px;
  height: 256px;
  border: 1px solid #f1f1f1;
  transition: all 300ms linear;
}

.more {
  cursor: pointer;
  color: #444;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
}

.portal {
  transition: all 300ms linear;
  line-height: 1;
  color: #000;
  box-shadow: 0px 2px 8px 0px rgba(162, 42, 71, 0.2);
  background-color: #fff;
}
