@import "../../variables";

.nav {
  flex: 1;
  padding-left: 32px;

  &-item {
    float: left;
    padding: 0 32px;
    cursor: pointer;
    transition: all 0.1s linear;
    color: #fff;
    text-decoration: none;

    &:hover {
      background-color: $hover-color;
    }
  }

  &-i18n {
    float: right;
    cursor: pointer;
    height: 64px;
    padding-top: 7px;
  }
}

.lang {
  background-color: #fff;
  box-shadow: 0px 1px 3px 2px rgb(162 42 71 / 20%);

  &-item {
    padding: 4px 8px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      background-color: #f9dee4;
      color: $color;
    }
  }
}
