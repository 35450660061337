@import "../variables";

.footer {
  background-color: $color;
  color: #aaa;
  padding: 32px;
}

.content {
  max-width: $max-width;
  margin: auto;
  display: flex;
}

.title {
  padding-bottom: 8px;
  color: #bbb;
}

.section {
  flex: 1;
}

.item {
  display: block;
  font-size: 14px;
  padding-bottom: 4px;
}

.icon-out {
  vertical-align: -2px;
  display: inline-block;
  margin-left: 4px;
  color: #aaa;
}

.item {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.more {
  font-size: 14px;
}
