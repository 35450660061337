@import "../../variables";

.product {
  max-width: $max-width;
  margin: auto;
  display: flex;
}

.menu-list {
  width: 200px;
}

.fixed-menu {
  // position: fixed;
  top: 64px;
  width: 200px;
  // background-color: #fff7f8;
}

.sub-title {
  font-size: 12px;
  color: #ccc;
  padding-top: 2px;
}

.menu-item {
  padding: 16px 0 16px 16px;
  cursor: pointer;
  transition: all 200ms linear;

  &.active,
  &:hover {
    background-color: #f9dee4;
    color: $color;
  }
}

.content {
  flex: 1;
  padding: 32px;
  border-left: 1px solid #f1f1f1;
}

.product {
  &-item {
    margin-bottom: 32px;
  }

  &-title {
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: bold;
  }

  &-description {
    color: #888;
    margin-bottom: 8px;
  }
}

.food-grade {
  ul {
    padding: 0;
    margin: 0;
  }

  li {
    padding-bottom: 6px;
  }

  dl {
    display: flex;
  }

  dt {
    width: 180px;
    color: #888;
  }

  dd {
    margin: 0;
    flex: 1;
  }
}
