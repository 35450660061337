@import "../variables";

.header {
  height: 64px;
  line-height: 64px;
  box-shadow: 0px 1px 0px 0px rgba(162, 42, 71, 0.2);
  background: $color;
  color: #fff;
  z-index: 10;
  position: relative;
}

.header-container {
  display: flex;
  max-width: $max-width;
  margin: auto;
}

.logo {
  font-size: 21px;
  margin: 0 16px;
  height: 64px;
}

.logo-name {
  font-size: 18px;
}
