@import "../../variables";

.container {
  max-width: $max-width;
  margin: auto;
}

.partners {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &-item {
    width: 33.33333333%;
    align-items: center;
  }
}

.logo {
  width: 200px;
  margin-bottom: 16px;
}

.name {
  color: #ccc;
}

.contract-list {
  margin: 16px 0 24px;
}

.contract-us {
  font-size: 18px;
}

.item {
  margin-bottom: 8px;

  a:hover {
    text-decoration: underline;
  }

  .icon-out {
    margin-left: 4px;
  }
}

.ph {
  text-indent: 2em;
  line-height: 1.5;
}

.context {
  display: flex;
  margin-bottom: 32px;

  &-left {
    padding-right: 16px;
  }
}
