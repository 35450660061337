.table {
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    text-align: left;
    border: 1px solid #0505050f;
    padding: 16px;
  }

  th {
    color: #444;
    border: 1px solid #0505050f;
    background-color: #fafafa;
  }

  td {
    color: #666;
  }

  + .table {
    margin-top: 10px;
  }
}
