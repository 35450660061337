.menu {
  transition: all 300ms linear;
  line-height: 1;
  color: #000;
  box-shadow: 0px 2px 8px 0px rgba(162, 42, 71, 0.2);
  background-color: #fff;

  &-item {
    padding: 16px 32px;
    font-size: 14px;
    cursor: pointer;
    display: block;

    &:hover {
      color: #a72a47;
      background-color: #f9dee4;
    }
  }
}
