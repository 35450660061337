.fr {
  float: right;
}

.fl {
  float: left;
}

.full-width {
  width: 100%;
}

.upper-case {
  text-transform: uppercase;
}
