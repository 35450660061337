@import "../../../../variables";

.pl {
  &-item {
    max-width: $max-width;
    margin: auto;
    display: flex;
    line-height: 1.4;
    margin-bottom: 24px;

    &:nth-child(odd) {
      .desc {
        border-right: 1px solid #f1f1f1;
      }
    }

    &:nth-child(even) {
      .desc {
        border-left: 1px solid #f1f1f1;
      }
    }

    .title {
      font-size: 18px;
      color: #777;
      padding-bottom: 8px;
    }

    .desc {
      flex: 1;
      padding: 16px;
      color: #888;
    }

    .img {
      width: 500px;
      text-align: center;
    }

    .more {
      text-decoration: underline;
      font-size: 14px;
    }
  }
}

.product-title {
  max-width: 1440px;
  margin: auto;
  font-weight: normal;
  margin-bottom: 16px;

  .slash {
    color: #ccc;
    padding: 0 6px;
  }

  .sub-title {
    font-size: 16px;
    color: #ccc;
  }
}
