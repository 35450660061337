@import "../../../variables";

.tabs {
  &-nav {
    display: flex;
    margin-bottom: 32px;
    border-bottom: 1px solid #f1f1f1;
  }

  &-tab {
    padding: 8px 16px;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &:hover {
      color: $color;
    }

    &.active {
      color: $color;
      border-bottom-color: #f9dee4;
    }
  }
}
